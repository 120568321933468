<template>
  <AtomsGlobalLink :to="props.url" class="magazine-item">
    <div class="overflow-hidden">
      <AtomsImagesImage :src="`${useShopZone().value.staticResourcesRoot}/img/magazine/${props.itemId}.jpg`" :alt="props.title" class="magazine-item__image" />
    </div>
    <div class="magazine-item__body">
      <div class="magazine-item__meta">
        <span v-if="props.timeToRead">{{ $t('Doba čtení') }}: <strong>{{ $t('minuta', props.timeToRead) }}</strong></span>
        <span v-else>{{ $t('Článek') }}</span>
        <span v-if="props.datePublished || props.dateCreated">
          <AtomsImagesIcon icon="calendar" class="mr-2 !align-baseline" />{{ getDate(props.datePublished ?? props.dateCreated) }}
        </span>
      </div>
      <component :is="props.titleElement ?? 'strong'" class="font-bold text-lg">{{ props.title }}</component>
      <div v-if="props.annotation" class="magazine-item__annotation">
        {{ props.annotation}}
      </div>
      <MoleculesButtonsButton v-if="props.withButton" :dummy="true" :black="true" :arrowRight="true" class="mt-auto">{{ $t('Celý článek') }}</MoleculesButtonsButton>
    </div>
  </AtomsGlobalLink>
</template>
<script setup>

const props = defineProps({
  title: String,
  titleElement: String | undefined,
  url: String,
  itemId: Number,
  datePublished: String,
  dateCreated: String,
  timeToRead: Number,
  annotation: String,
  withButton: Boolean,
});

const appConfig = useAppConfig();
const locale = useLocale();

const getDate = (inputDate) => {
  const date = new Date(inputDate);
  return date.toLocaleDateString(locale.getLocale().locale, { year: 'numeric', month: 'long', day: 'numeric' });
};

</script>
<style lang="postcss">
.magazine-item {
  @apply flex-1 flex flex-col;

  &:hover {
    @apply no-underline;
  }

  &__body {
    @apply flex-1 flex flex-col gap-4 p-4 bg-neutral-200;
  }

  &__meta {
    @apply relative flex justify-between items-center font-bold uppercase text-xs text-neutral-400;

    &>* {
      @apply relative z-[1] bg-neutral-200;
    }

    &>*:first-child {
      @apply pr-4;
    }

    &>*:last-child {
      @apply pl-4;
    }

    &:before {
      @apply content-[''] absolute left-0 right-0 border-b border-neutral-400;
    }
  }

  &__image {
    @apply w-full transition-all duration-300;

    &:hover {
      @apply scale-[1.05];
    }
  }

}
</style>